import { AnswerCallback, AnswerOption, DiscardedCallback } from "@/components/QuestionModal/QuestionModal";
import { monitor } from "@/utils/monitoring.service";
import { useCallback, useEffect, useState } from "react"

const LOCAL_STORAGE_KEY = "user-questions-progress"

export interface QuestionDefinitions {
  [question: string]: AnswerOption[];
}

export interface iUseQuestionnaire {
  questions: QuestionDefinitions
}

export interface UserQuestionAnswer {
  [question: string]: AnswerOption
}

export interface UserQuestionProgress {
  completed: UserQuestionAnswer,
  discarded: string[]
  expire: number
}

const addTimeToUnix = (hours: number = 0, days: number = 0, weeks: number = 0) => {
  const oneHour = 1000 * 60 * 60;
  const h = oneHour * hours;
  const d = oneHour * 24 * days;
  const w = oneHour * 24 * 7 * weeks;
  return h + d + w;
}

export const useQuestionnaire = ({questions}: iUseQuestionnaire) => {
	const [question, setQuestion] = useState<string>("");
	const [answerOptions, setAnswerOptions] = useState<AnswerOption[]>([]);
	let questionProgress: UserQuestionProgress = {
	  completed: {},
	  discarded: [],
	  expire: Date.now() + addTimeToUnix(0, 0, 2)
	}
	
	const _addCompletedQuestion = useCallback((q: string, a: AnswerOption) => {
	  questionProgress.completed[q] = a;
	  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(questionProgress));
	  resetQuestionnaire();
	}, []);

	const _addDiscardedQuestion = useCallback((q: string) => {
	  questionProgress.discarded.push(q);
	  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(questionProgress));
	  resetQuestionnaire();
	}, []);

	const _canAskQuestion = useCallback((q: string) => {
	  return !(questionProgress.discarded.includes(q) || Object.keys(questionProgress.completed).includes(q))
	}, []);

	const questionAnswerCallback: AnswerCallback = (question: string, answer: AnswerOption) => {
	  monitor.questionAnswered({
		'question': question,
		'answer': answer
	  });
	  _addCompletedQuestion(question, answer);
	};

	const questionDiscardCallback: DiscardedCallback = (question: string) => {
	  _addDiscardedQuestion(question);
	};
	
	const resetQuestionnaire = useCallback(() => {
	  setQuestion("");
	  setAnswerOptions([]);
	}, []);

	const nextQuestion = useCallback(() => {
	  for (const [q, ao] of Object.entries(questions)) {
		if (_canAskQuestion(q)) {
			setQuestion(q);
			setAnswerOptions(ao);
			return;
		}
	  }

	  // No questions left
	  resetQuestionnaire();
	}, [questions]);

	const _deleteProgress = () => {
	  localStorage.removeItem(LOCAL_STORAGE_KEY)
	}

	const _loadProgess = () => {
	  try {
		  const _v = localStorage.getItem(LOCAL_STORAGE_KEY);
		  if (!_v) return;
		  const loadedCompletedQuestions: UserQuestionProgress = JSON.parse(_v);
		  if (loadedCompletedQuestions.expire <= Date.now()) {
			_deleteProgress();
		  } else {
			questionProgress = loadedCompletedQuestions;
		  }

	  } catch (e) {
		  // if failed to load, delete empty
		  _deleteProgress();
		  console.warn(e);
	  }
	}

	useEffect(() => {
		_loadProgess();
	}, []);

	return {
		question,
		answerOptions,
		questionAnswerCallback,
		questionDiscardCallback,
		nextQuestion,
		resetQuestionnaire,
	};
}
