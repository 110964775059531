import { useTranslation } from "react-i18next";
import { IconClose } from "../Icons";
import cx from "classnames";

export type AnswerCallback = (question: string, answer: AnswerOption) => void;
export type DiscardedCallback = (question: string) => void;
export interface AnswerOption {
  name: string;
  value: string | number;
}

export interface iQuestionModal {
  question: string;
  answerOptions: AnswerOption[];
  answerCallback: AnswerCallback;
  discardedCallback?: DiscardedCallback;
}

export const QuestionModal = ({ question, answerOptions, answerCallback, discardedCallback = () => {} }: iQuestionModal) => {
  const { t } = useTranslation();

  return (
    <div
      id="question-modal"
      className="relative py-4 rounded-3xl w-full flex flex-col gap-4 sm:w-[321px] bg-brand-secondary-64 dark:bg-brand-tertiary-64"
    >
      <div
        className="absolute top-2 right-2 p-1 min-w-6 min-h-6 flex items-center justify-center cursor-pointer"
        onClick={() => discardedCallback(question)}
      >
        <IconClose width={10} heigth={10} className="fill-brand-tertiary" />
      </div>

      <div className="flex w-full justify-center">
        <span className="text-support-100 font-semibold w-full text-center mx-8 pb-2 text-base border-brand-tertiary-64 border-b dark:border-support-100">
          {t(question)}
        </span>
      </div>

      <div className="w-full max-w-full text-support-100">
        <div className="flex justify-center gap-2 px-2 flex-wrap text-sm">
          {answerOptions.map((ao) => {
            return (
              <span
                key={ao.value}
                className="px-4 py-1 rounded-2xl bg-brand-secondary dark:bg-brand-tertiary cursor-pointer transition-all duration-150 ease-in-out font-semibold hover:bg-brand-primary"
                onClick={() => {
                  answerCallback(question, ao);
                }}
              >
                {t(ao.name)}
              </span>
            );
          })}
        </div>
      </div>
    </div>
  );
};
