import { QuestionDefinitions } from "@/hooks/questionnaire.hook";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AuthenticationMethod, PluginSettings, StreamingMetadata } from "intelliprove-streaming-sdk";

export type SettingState = {
  onboarding: boolean;
  duration?: number;
  actionToken?: string | null;
  authenticationMethod?: AuthenticationMethod;
  metadata: StreamingMetadata;
  pluginSettings?: Omit<PluginSettings, "json">;
  language?: string;
  questionnaireQuestions: QuestionDefinitions;
};

const initialState: SettingState = {
  onboarding: false,
  duration: 20000,
  actionToken: undefined,
  authenticationMethod: AuthenticationMethod.ActionToken,
  metadata: {
    patient: null,
    performer: null,
    userId: null,
    sessionId: null,
  },
  pluginSettings: new PluginSettings({
    skip_results: false,
    skip_welcome: false,
    hide_check_privacy: false,
    hide_check_data_usage: false,
    hide_watermark: false,
    url_privacy_policy: null,
    url_product_guidelines: null,
    url_terms_of_service: null,
    url_website: null,
    embedded_iframe: false,
    embedded_app: false,
    feature_flags: {
      trends_page: false,
      live_questionnaire: false,
      live_results: false,
    },
    theming: {},
  }),
  questionnaireQuestions: {},
  language: "en",
};

export const settingSlice = createSlice({
  name: "setting",
  initialState,
  reducers: {
    setSetting: (state, action: PayloadAction<SettingState>) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    setOnboarding: (state, action: PayloadAction<boolean>) => {
      state.onboarding = action.payload;
    },
    setMetadata: (state, action: PayloadAction<StreamingMetadata>) => {
      state.metadata = action.payload;
    },
    setDuration: (state, action: PayloadAction<number>) => {
      state.duration = action.payload;
    },
    setPluginSetting: (state, action: PayloadAction<Omit<PluginSettings, "json">>) => {
      state.pluginSettings = {
        ...initialState,
        ...action.payload,
      };
    },
    setQuestionnaireQuestions: (state, action: PayloadAction<QuestionDefinitions>) => {
      state.questionnaireQuestions = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setSetting, setOnboarding, setMetadata, setDuration, setPluginSetting } = settingSlice.actions;

export default settingSlice.reducer;
