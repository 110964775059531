import { QuestionDefinitions } from "@/hooks/questionnaire.hook";


export class QuestionnaireError extends Error {}

export const fetchQuestions = async (): Promise<QuestionDefinitions> => {
  const response = await fetch("https://s3.eu-west-1.amazonaws.com/plugin.rc.intelliprove.com/live_questionnaire_data_source.json")
  if (response.status !== 200) {
	throw new QuestionnaireError("Failed to fetch the questionnaire JSON");
  }

  try {
	const questions: QuestionDefinitions = await response.json();
	return questions;
  } catch (e) {
	console.error(e);
	throw new QuestionnaireError("Failed to decode questionnaire JSON");
  }
}
