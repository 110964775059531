import { datadogLogs } from "@datadog/browser-logs";
import { datadogRum } from "@datadog/browser-rum";

interface RumResource {
  duration: number;
  id: string;
  method: string;
  status_code: number;
  type: string;
  url: string;
}

export class DatadogService {
  static setUpLogging(environment: string) {
    datadogLogs.init({
      clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN ?? "",
      site: "datadoghq.eu",
      service: `intelliprove-web-plugin`,
      env: environment,
      version: process.env.REACT_APP_VERSION ?? "0.0.1",
      sessionSampleRate: 100,
    });
  }

  static setUpRum(environment: string) {
    datadogRum.init({
      applicationId: process.env.REACT_APP_DATADOG_APP_ID ?? "",
      clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN ?? "",
      site: "datadoghq.eu",
      service: `intelliprove-web-plugin`,
      env: environment,
      version: process.env.REACT_APP_VERSION ?? "0.0.1",
      trackingConsent: "granted",
      // trackSessionAcrossSubdomains: true,
      usePartitionedCrossSiteSessionCookie: window.self !== window.top, // Turn on if running in iframe
      sessionSampleRate: 100,
      trackUserInteractions: true,
      trackViewsManually: true, // Needed for React
      defaultPrivacyLevel: "mask-user-input",
      beforeSend: (event) => {
        let type: string = event.type;
        let action: any = event.action;
        return type === "view" || (action && action.type === "custom");
      },
    });
  }

  static setUser(metadata: object) {
    datadogRum.setUser({
      ...metadata,
    });
  }

  static sendRumAction(action: string, context: object) {
    datadogRum.addAction(action, context);
  }
}
