import { configureStore } from '@reduxjs/toolkit'
import logger from 'redux-logger'

import settingReducer from './reducers/setting.reducer'
import globalErrorReducer from './reducers/globalError.reducer'
import measurementReducer from './reducers/measurement.reducer'

const store =  configureStore({
  reducer: {
    setting: settingReducer,
    globalError: globalErrorReducer,
    measurement: measurementReducer,
  },
  middleware: (getDefaultMiddleware) => {
    if (process.env.NODE_ENV !== 'production') {
      return getDefaultMiddleware({ serializableCheck: false }).concat(logger);
    }

    return getDefaultMiddleware({ serializableCheck: false });
  },
  devTools: process.env.NODE_ENV !== 'production',
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store